<template>
	<div class="box">
		<CustomTable
			id_table="embryons_available"
			:items="embryons"
			:busy.sync="table_busy"
			primaryKey="embryon_id"
			:hrefsRoutes="config_table_hrefs"
			:externSlotColumns="['embryon_stade']"
		>
			<template v-slot:[`custom-slot-cell(embryon_stade)`]="{ data }">
				{{ $t('gynecologie.embryon.'+data.embryon_stade) }}
			</template>
		</CustomTable>

		<b-modal ref="modalEditEmbryon" size="lg" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("gynecologie.edit_embryon") }}
				</template>
			</template>

			<div>
				<b-checkbox v-model="embryon_congele" class="mr-3 d-inline">
					{{ $t('gynecologie.embryon.congele')}}
				</b-checkbox>

				<b-checkbox v-model="embryon_detruit" class="d-inline">
					{{ $t('gynecologie.embryon.detruit')}}
				</b-checkbox>
			</div>

			<div class="form-group">
				<label for="skip" class="col-form-label">{{ $t("gynecologie.embryon.commentaire") }}</label>
				<input type="text" class="form-control" v-model="embryon_commentaire">
			</div>

			<div class="row mt-2 align-items-center">
				<div class="col-auto">
					<b-input-group :prepend="$t('gynecologie.embryon.taille')">
						<b-input v-model="embryon_taille" />
					</b-input-group>
				</div>
				<div class="mt-3 mt-lg-0 col-lg">
					<b-input-group :prepend="$t('gynecologie.embryon.stade')">
                        <e-select
                            v-model="embryon_stade"
                            :placeholder="getTrad('gynecologie.embryon.select_stade')"
                            :options="all_stades"
                            :searchable="true"
                            :show-labels="false"
						>
							<template slot="option" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
							<template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
                        	<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</b-input-group>
				</div>
			</div>

			<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="edit">{{ $t("global.modifier") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
		</b-modal>

		<b-modal ref="modalAddEmbryon" size="lg" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("gynecologie.add_embryon") }}
				</template>
			</template>

			<div class="form-group">
        		<label class="col-form-label">{{ $t('monte.etalon') }} *</label>
                <e-select
                    v-model="embryon_stallion"
                    id="stallion"
                    track-by="horse_id"
                    label="horse_nom"
                    :placeholder="$t('monte.selectionnez_etalon')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="stallions"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                />
            </div>

			<div class="form-group">
        		<label class="col-form-label">{{ $t('monte.jument') }} *</label>
                <e-select
                    v-model="embryon_mare"
                    id="stallion"
                    track-by="horse_id"
                    label="horse_nom"
                    :placeholder="$t('monte.selectionnez_jument')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="mares"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                />
            </div>

			<div>
				<b-checkbox v-model="embryon_congele" class="mr-3 d-inline">
					{{ $t('gynecologie.embryon.congele')}}
				</b-checkbox>

				<b-checkbox v-model="embryon_detruit" class="d-inline">
					{{ $t('gynecologie.embryon.detruit')}}
				</b-checkbox>
			</div>

			<div class="form-group">
				<label for="skip" class="col-form-label">{{ $t("gynecologie.embryon.commentaire") }}</label>
				<input type="text" class="form-control" v-model="embryon_commentaire">
			</div>

			<div class="row mt-2 align-items-center">
				<div class="col-auto">
					<b-input-group :prepend="$t('gynecologie.embryon.taille')">
						<b-input v-model="embryon_taille" />
					</b-input-group>
				</div>
				<div class="mt-3 mt-lg-0 col-lg">
					<b-input-group :prepend="$t('gynecologie.embryon.stade')">
                        <e-select
                            v-model="embryon_stade"
                            :placeholder="getTrad('gynecologie.embryon.select_stade')"
                            :options="all_stades"
                            :searchable="true"
                            :show-labels="false"
						>
							<template slot="option" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
							<template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
                        	<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</b-input-group>
				</div>
			</div>

			<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="add">{{ $t("global.ajouter") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
		</b-modal>

		<b-modal ref="modalConfirmDeleteEmbryon" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("gynecologie.embryon.title_suppression") }}
				</template>
			</template>

			<div class="text-center">
				{{ $t("gynecologie.embryon.confirm_suppression") }}<br>

				<b-button class="mt-3 rounded-pill" variant="primary" @click="toDelete">{{ $t("global.supprimer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>

		<b-modal ref="modalExpeditionEmbryon" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("gynecologie.embryon.expedition") }}
				</template>
			</template>

			<div class="text-center">
				<SearchTiers 
					:tiers_selected.sync="destinataire"
				/>

				<b-button class="mt-3 rounded-pill" variant="primary" @click="toExpedition">{{ $t("global.valider") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import Embryon from '@/mixins/Embryon'
import Navigation from "@/mixins/Navigation.js";

export default {
	name: 'EmbryonAvailable',
	mixins: [Embryon, Navigation],
	data () {
		return {
			table_busy: true,
			embryons: [],
			embryon_id: null,
			embryon_detruit: null,
			embryon_congele: null,
			embryon_commentaire: null,
			embryon_taille: '',
			embryon_stade: '-',
			embryon_mare: null,
			embryon_stallion: null,
			all_stades: ['-','MOR','JBL','BLA','BLE'],
			processing: false,
			embryon_to_delete: [],
			destinataire: null,
			embryon_to_exp: [],
			stallions: [],
			mares: [],
			config_table_hrefs: {
				'collecte.horse.horse_nom': {
					routeUniqueName: 'MareFicheInfo',
					routeName: 'HorseFicheInfo',
					params: {
						horse_id: 'collecte.horse.horse_id'
					}
				},
				'collecte.season_mare_stallion.horse.horse_nom': {
					routeUniqueName: 'StallionFicheInfo',
					routeName: 'HorseFicheInfo',
					params: {
						horse_id: 'collecte.season_mare_stallion.horse.horse_id'
					}
				}
			},
			events_tab: {
				'TableAction::goToEditEmbryon': (embryon) => {
					this.openModal(embryon)
				},
				'TableAction::goToDeleteEmbryon': (embryon) => {
					this.checkTransplantation(embryon)
				},
				'TableAction::goToExpditionEmbryon': (embryons) => {
					this.askDestinataire(embryons)
				},
				'TableAction::goToAddEmbryon': () => {
					this.openModal()
				},
			}
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.stallions = await this.getHorsesStallion(false)
			this.mares = await this.getHorseFemelle()

			try {
				this.table_busy = true

				this.embryons = await this.getAvailableEmbryons()
			}
			finally {
				this.table_busy = false
			}
		},
		openModal(embryon) {
			if(embryon) {
				this.embryon_id = embryon.embryon_id
				this.embryon_detruit = embryon.embryon_detruit
				this.embryon_congele = embryon.embryon_congele
				this.embryon_commentaire = embryon.embryon_commentaire
				this.embryon_taille = embryon.embryon_taille
				this.embryon_stade = embryon.embryon_stade
				this.$refs.modalEditEmbryon.show()
			}
			else {
				this.embryon_id = null
				this.embryon_detruit = false
				this.embryon_congele = false
				this.embryon_commentaire = ''
				this.embryon_taille = ''
				this.embryon_stade = null
				this.embryon_mare = null
				this.embryon_stallion = null
				this.$refs.modalAddEmbryon.show()
			}
		},
		checkTransplantation(embryon) {
			this.embryon_to_delete = embryon
			if(this.embryon_to_delete.transplantation) {
				this.$refs.modalConfirmDeleteEmbryon.show()
			}
			else {
				this.toDelete()
			}
		},
		toDelete() {
			this.deleteEmbryon(this.embryon_to_delete.embryon_id)
			.then(() => {
				this.processing = false
				if(this.$refs.modalConfirmDeleteEmbryon) {
					this.$refs.modalConfirmDeleteEmbryon.hide()
				}
				this.init_component()
			})
		},
		edit() {
			this.processing = true
			this.editEmbryon(this.embryon_id, this.embryon_detruit, this.embryon_commentaire, this.embryon_taille, this.embryon_stade, this.embryon_congele)
			.then(() => {
				this.processing = false
				this.$refs.modalEditEmbryon.hide()
				this.init_component()
			})
		},
		add() {
			if(!this.embryon_mare || !this.embryon_stallion) {
				return false
			}
			this.processing = true
			let embryon = {
				embryon_detruit: this.embryon_detruit,
				embryon_commentaire: this.embryon_commentaire,
				embryon_taille: this.embryon_taille,
				embryon_stade: this.embryon_stade,
				embryon_congele: this.embryon_congele,
				embryon_mare: this.embryon_mare.horse_id,
				embryon_stallion: this.embryon_stallion.horse_id
			}
			this.addEmbryon(embryon)
			.then(() => {
				this.processing = false
				this.$refs.modalAddEmbryon.hide()
				this.init_component()
			})
		},
		askDestinataire(embryons) {
			this.embryon_to_exp = embryons
			this.$refs.modalExpeditionEmbryon.show()
		},
		toExpedition() {
			this.expeditionEmbryon(this.embryon_to_exp, this.destinataire.tiers_id)
			.then(() => {
				this.$refs.modalExpeditionEmbryon.hide()
				this.init_component()
			})
		}
	},
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		SearchTiers : () => import('@/components/Contract/SearchTiers')
	}
}
</script>
